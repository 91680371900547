<template>
  <div class="app">
    <img class="Vuelogo" src="./assets/logo.png">
  
    <div class="text" >
      <a href="https://beian.miit.gov.cn">粤ICP备2023045222号-1</a>

    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style  lang="less">

.app{

align-items: center;
  width:100vw;
  .Vuelogo{
    width: 100%;
}
.text{
  text-align: center;
  padding-bottom: 50px;
  font-size: 14px;
    color: darkgrey;

}
}



</style>
